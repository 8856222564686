"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var react_router_dom_1 = require("react-router-dom");
function useChangeParams(fn, key) {
    var params = (0, react_router_dom_1.useParams)();
    (0, react_1.useEffect)(function () {
        fn(params[key]);
    }, [params[key]]);
}
exports.default = useChangeParams;
