"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var theme = {
    primary: {
        back: "#004e98",
        front: "#fff"
    },
    primaryLight: {
        back: "#3A6EA5",
        front: "#fff",
    },
    background_0: {
        back: "#EBEBEB",
        front: "#000"
    },
    background_1: {
        back: "#D5DFE5",
        front: "#000"
    },
    background_2: {
        back: "#C0C0C0",
        front: "#000"
    },
    accent: {
        back: "#FF6700",
        front: "#fff"
    },
    success: {
        back: "#20bf55",
        front: "#fff"
    },
    error: {
        back: "#FF5964",
        front: "#fff"
    },
    errorLight: {
        back: "#ffcccc",
        front: "#000"
    },
    warning: {
        back: "#F4E409",
        front: "#000"
    }
};
exports.default = theme;
