"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var mobx_1 = require("mobx");
var APIReesponseGuards_1 = require("shared/guards/APIReesponseGuards");
function easeInOutSine(x) {
    return -(Math.cos(Math.PI * x) - 1) / 2;
}
var SmoothProgress = /** @class */ (function () {
    function SmoothProgress(onprogress) {
        this.running = false;
        this.progress = 0;
        this.animate_from = 0;
        this.animate_pos = 0;
        this.animate_to = 0;
        this.onprogress = onprogress;
        (0, mobx_1.makeAutoObservable)(this, {}, { autoBind: true });
    }
    SmoothProgress.prototype.setDesired = function (desired) {
        this.animate_to = Math.min(Math.max(desired, 0), 1) * SmoothProgress.END;
        if (!this.running)
            this.start();
        this.animate_from = Math.floor(this.progress);
        this.animate_pos = 0;
    };
    Object.defineProperty(SmoothProgress.prototype, "delta", {
        get: function () {
            return this.animate_to - this.animate_from;
        },
        enumerable: false,
        configurable: true
    });
    SmoothProgress.prototype.step = function () {
        if (!this.running)
            return;
        if (this.animate_pos >= this.delta) {
            this.running = false;
            this.progress = this.animate_to;
        }
        else {
            this.animate_pos += SmoothProgress.UNIT_INCR;
            this.progress = this.animate_from + easeInOutSine((this.animate_pos) / (this.delta)) * (this.delta);
        }
        this.onprogress(this.progress / SmoothProgress.END);
        requestAnimationFrame(this.step);
    };
    Object.defineProperty(SmoothProgress.prototype, "isBusy", {
        get: function () {
            return this.running;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SmoothProgress.prototype, "isEnded", {
        get: function () {
            return this.progress === SmoothProgress.END;
        },
        enumerable: false,
        configurable: true
    });
    // each animation frame moves the progress towards the desired value
    SmoothProgress.prototype.start = function () {
        this.running = true;
        requestAnimationFrame(this.step);
    };
    SmoothProgress.prototype.reset = function () {
        this.progress = 0;
        this.animate_from = 0;
        this.animate_pos = 0;
        this.animate_to = 0;
        this.onprogress(0);
    };
    SmoothProgress.END = 10000;
    SmoothProgress.UNIT_INCR = 50;
    return SmoothProgress;
}());
var UploadStore = /** @class */ (function () {
    function UploadStore() {
        var _this = this;
        this._fraction = 0;
        this._uploadCommand = null;
        this.smoothProgress = new SmoothProgress(function (frac) { return (0, mobx_1.runInAction)(function () { return _this._fraction = frac; }); });
        (0, mobx_1.makeAutoObservable)(this, {}, { autoBind: true });
    }
    Object.defineProperty(UploadStore.prototype, "hasCommand", {
        get: function () {
            return this._uploadCommand !== null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UploadStore.prototype, "frac", {
        get: function () {
            return this._fraction;
        },
        set: function (frac) {
            if (this._uploadCommand === null)
                return;
            if (this._fraction >= frac)
                return;
            this.smoothProgress.setDesired(frac);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UploadStore.prototype, "percent", {
        get: function () {
            if (this._uploadCommand === null)
                return "";
            return UploadStore.PercentFormatter.format(this._fraction);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UploadStore.prototype, "isBusy", {
        get: function () {
            if (this._uploadCommand === null)
                return false;
            return this._uploadCommand.isBusy || this.smoothProgress.isBusy;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UploadStore.prototype, "isLoaded", {
        get: function () {
            if (this._uploadCommand === null)
                return false;
            return this._uploadCommand.isSuccess && this.smoothProgress.isEnded;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UploadStore.prototype, "isFailed", {
        get: function () {
            if (this._uploadCommand === null)
                return false;
            return this._uploadCommand.isFailure && this.smoothProgress.isEnded;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UploadStore.prototype, "isLoadedOrFailed", {
        get: function () {
            return this.isLoaded || this.isFailed;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UploadStore.prototype, "error", {
        get: function () {
            if (this._uploadCommand === null)
                return "";
            if ((0, APIReesponseGuards_1.isAPIErrorDTO)(this._uploadCommand.error))
                return this._uploadCommand.error.message;
            return "";
        },
        enumerable: false,
        configurable: true
    });
    UploadStore.prototype.setCommand = function (command) {
        this._uploadCommand = command;
    };
    UploadStore.prototype.reset = function () {
        if (this._uploadCommand === null)
            return;
        this._fraction = 0;
        this._uploadCommand = null;
        this.smoothProgress.reset();
    };
    UploadStore.PercentFormatter = Intl.NumberFormat('it', {
        style: 'percent',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });
    return UploadStore;
}());
exports.default = UploadStore;
