"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var DocFileVerificationStateDict = {
    APPROVED: "APPROVED",
    MISSING: "MISSING",
    REJECTED: "REJECTED",
    CLIENT_APPROVED: "CLIENT_APPROVED",
    CLIENT_REJECTED: "CLIENT_REJECTED",
};
exports.default = DocFileVerificationStateDict;
