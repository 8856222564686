"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var mobx_1 = require("mobx");
//class that handles debouncing task
var DebounceTask = /** @class */ (function () {
    function DebounceTask(callback, delay) {
        if (delay === void 0) { delay = DebounceTask.DEFAULT_DELAY; }
        this.timeout = null;
        this.callback = callback;
        this.delay = delay;
        (0, mobx_1.makeAutoObservable)(this, {}, { autoBind: true });
    }
    Object.defineProperty(DebounceTask.prototype, "isBusy", {
        get: function () {
            return !!this.timeout;
        },
        enumerable: false,
        configurable: true
    });
    DebounceTask.prototype.start = function () {
        var _this = this;
        this.timeout = setTimeout(function () {
            (0, mobx_1.runInAction)(function () {
                _this.callback();
                _this.clear();
            });
        }, this.delay);
    };
    DebounceTask.prototype.stop = function () {
        if (this.timeout) {
            clearTimeout(this.timeout);
            this.clear();
        }
    };
    DebounceTask.prototype.clear = function () {
        this.timeout = null;
    };
    DebounceTask.prototype.call = function () {
        this.stop();
        this.start();
    };
    DebounceTask.prototype.cancel = function () {
        this.stop();
    };
    DebounceTask.DEFAULT_DELAY = 250;
    return DebounceTask;
}());
exports.default = DebounceTask;
