"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Cliente = /** @class */ (function () {
    function Cliente(props) {
        this.props = props;
    }
    Object.defineProperty(Cliente.prototype, "_id", {
        get: function () {
            return this.props._id;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Cliente.prototype, "codice_fiscale", {
        get: function () {
            return this.props._id;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Cliente.prototype, "partita_iva", {
        get: function () {
            return this.props._id;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Cliente.prototype, "ragione_sociale", {
        get: function () {
            return this.props.ragione_sociale;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Cliente.prototype, "email", {
        get: function () {
            return this.props.email;
        },
        enumerable: false,
        configurable: true
    });
    Cliente.create = function (props) {
        return new Cliente(props);
    };
    return Cliente;
}());
exports.default = Cliente;
