"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Page = /** @class */ (function () {
    function Page(data) {
        this._req = data.req;
        this._data = data.data;
        this._next = data.pagination.next;
        this._prev = data.pagination.prev;
    }
    Object.defineProperty(Page.prototype, "size", {
        get: function () {
            return this._data.length;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Page.prototype, "data", {
        get: function () {
            return this._data;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Page.prototype, "hasNext", {
        get: function () {
            return this._next !== null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Page.prototype, "nextReq", {
        get: function () {
            if (!this._next)
                return null;
            return this._req.next(this._next);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Page.prototype, "hasPrev", {
        get: function () {
            return this._prev !== null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Page.prototype, "prevReq", {
        get: function () {
            if (!this._prev)
                return null;
            return this._req.prev(this._prev);
        },
        enumerable: false,
        configurable: true
    });
    Page.create = function (data) {
        return new Page(data);
    };
    return Page;
}());
exports.default = Page;
