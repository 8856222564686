"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isAPIErrorDTO = void 0;
/** checks if unknown is APIErrorDTO */
function isAPIErrorDTO(obj) {
    return !!obj && typeof obj === "object" && obj !== null &&
        ("message" in obj && typeof obj["message"] === "string") &&
        ("status" in obj && (obj["status"] === null || typeof obj["status"] === "number"));
}
exports.isAPIErrorDTO = isAPIErrorDTO;
