"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable mobx/exhaustive-make-observable */
var CryptoJS = __importStar(require("crypto-js"));
var mobx_1 = require("mobx");
var CryptoJsAlgorithm = /** @class */ (function () {
    function CryptoJsAlgorithm(hasher) {
        this.hasher = hasher;
        (0, mobx_1.makeObservable)(this, {
            end: true,
            append: true,
            reset: true,
        }, { autoBind: true });
    }
    CryptoJsAlgorithm.prototype.arrayBufferToWordArray = function (ab) {
        var i8a = new Uint8Array(ab);
        var a = [];
        for (var i = 0; i < i8a.length; i += 4) {
            a.push(i8a[i] << 24 | i8a[i + 1] << 16 | i8a[i + 2] << 8 | i8a[i + 3]);
        }
        return CryptoJS.lib.WordArray.create(a, i8a.length);
    };
    CryptoJsAlgorithm.prototype.appendWordArray = function (wordArray) {
        this.hasher.update(wordArray);
    };
    CryptoJsAlgorithm.prototype.append = function (buffer) {
        this.appendWordArray(this.arrayBufferToWordArray(buffer));
    };
    CryptoJsAlgorithm.prototype.end = function () {
        return this.hasher.finalize().toString();
    };
    CryptoJsAlgorithm.prototype.reset = function () {
        this.hasher.reset();
    };
    return CryptoJsAlgorithm;
}());
exports.default = CryptoJsAlgorithm;
