"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var RejectReasonTextDict = {
    DATE_ERROR: "senza data / data errata",
    UNREADABLE: "non leggibile",
    INCOMPLETE: "incompleto",
    USELESS: "inutile / sovrabbondante",
    OTHER: "altro",
};
exports.default = RejectReasonTextDict;
