"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var CacheConfig = /** @class */ (function () {
    function CacheConfig(config) {
        this.MaxAge = config.MaxAge;
        this.StaleWhileRevalidate = config.StaleWhileRevalidate;
    }
    Object.defineProperty(CacheConfig.prototype, "TTL", {
        get: function () {
            return this.MaxAge + this.StaleWhileRevalidate;
        },
        enumerable: false,
        configurable: true
    });
    CacheConfig.prototype.isFresh = function (age) {
        return age !== null && age < this.MaxAge;
    };
    CacheConfig.prototype.isStale = function (age) {
        return age !== null && age >= this.MaxAge && age <= this.TTL;
    };
    CacheConfig.prototype.isExpired = function (age) {
        return age !== null && age > this.StaleWhileRevalidate;
    };
    CacheConfig.StaleWhileRevalidate = function (MaxAge, StaleWhileRevalidate) {
        if (MaxAge === void 0) { MaxAge = CacheConfig.DEFAULT_MAX_AGE; }
        if (StaleWhileRevalidate === void 0) { StaleWhileRevalidate = CacheConfig.DEFAULT_STALE_WHILE_REVALIDATE; }
        return new CacheConfig({
            MaxAge: MaxAge,
            StaleWhileRevalidate: StaleWhileRevalidate,
        });
    };
    CacheConfig.DEFAULT_MAX_AGE = 15 * 1000;
    CacheConfig.DEFAULT_STALE_WHILE_REVALIDATE = 60 * 1000;
    return CacheConfig;
}());
exports.default = CacheConfig;
