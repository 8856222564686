"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function monitorAll(obj) {
    return new Proxy(obj, {
        get: function (target, key) {
            var value = target[key];
            console.log("Getting ".concat(String(key)));
            if (typeof value === 'function') {
                console.log("Getting ".concat(String(key)));
                return function () {
                    var args = [];
                    for (var _i = 0; _i < arguments.length; _i++) {
                        args[_i] = arguments[_i];
                    }
                    console.log("Calling ".concat(String(key), " with"), args);
                    return value.apply(target, args);
                };
            }
            return value;
        },
    });
}
var MockRepo = /** @class */ (function () {
    function MockRepo() {
        monitorAll(this);
    }
    return MockRepo;
}());
exports.default = MockRepo;
