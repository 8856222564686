"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var mobx_1 = require("mobx");
var Email = /** @class */ (function () {
    function Email(val) {
        this.dirty = false;
        this.props = val;
        (0, mobx_1.makeAutoObservable)(this, {}, { autoBind: true });
    }
    Object.defineProperty(Email.prototype, "value", {
        get: function () {
            return this.props;
        },
        set: function (val) {
            this.props = val;
            this.dirty = true;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Email.prototype, "isChanged", {
        get: function () {
            return this.dirty;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Email.prototype, "isValid", {
        get: function () {
            return /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(this.props);
        },
        enumerable: false,
        configurable: true
    });
    Email.create = function (value) {
        return new Email(value !== null && value !== void 0 ? value : "");
    };
    return Email;
}());
exports.default = Email;
