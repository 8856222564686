"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var mobx_1 = require("mobx");
var DataRequestPage = /** @class */ (function () {
    function DataRequestPage(params) {
        this.params = params;
        (0, mobx_1.makeAutoObservable)(this, {}, { autoBind: true });
    }
    Object.defineProperty(DataRequestPage.prototype, "filter", {
        get: function () {
            return this.params.filter;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DataRequestPage.prototype, "cursor", {
        get: function () {
            return this.params.cursor;
        },
        set: function (cursor) {
            this.params.cursor = cursor;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DataRequestPage.prototype, "limit", {
        get: function () {
            return this.params.limit;
        },
        enumerable: false,
        configurable: true
    });
    DataRequestPage.prototype.getKey = function () {
        var sortedParams = Object.fromEntries(Object.entries(this.params.filter).sort());
        return JSON.stringify(sortedParams);
    };
    Object.defineProperty(DataRequestPage.prototype, "order", {
        get: function () {
            return this.params.order;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DataRequestPage.prototype, "key", {
        get: function () {
            return this.params.key;
        },
        enumerable: false,
        configurable: true
    });
    DataRequestPage.prototype.next = function (cursor) {
        return DataRequestPage.create({
            filter: this.filter,
            cursor: cursor,
            limit: this.limit
        });
    };
    DataRequestPage.prototype.prev = function (cursor) {
        return DataRequestPage.create({
            filter: this.filter,
            cursor: cursor,
            limit: this.limit
        });
    };
    DataRequestPage.create = function (params) {
        return new DataRequestPage({
            filter: (params === null || params === void 0 ? void 0 : params.filter) || {},
            cursor: (params === null || params === void 0 ? void 0 : params.cursor) || null,
            limit: (params === null || params === void 0 ? void 0 : params.limit) || 25,
            order: (params === null || params === void 0 ? void 0 : params.order) || undefined,
            key: (params === null || params === void 0 ? void 0 : params.key) || undefined
        });
    };
    return DataRequestPage;
}());
exports.default = DataRequestPage;
