"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Record = /** @class */ (function () {
    function Record(props) {
        this.props = props;
    }
    Object.defineProperty(Record.prototype, "timestamp_id", {
        get: function () {
            return this.props.timestamp_id;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Record.prototype, "name", {
        get: function () {
            return this.props.name;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Record.prototype, "value", {
        get: function () {
            return this.props.value;
        },
        enumerable: false,
        configurable: true
    });
    Record.create = function (props) {
        return new Record(props);
    };
    return Record;
}());
exports.default = Record;
