"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var mobx_1 = require("mobx");
var FileViewMoveViewModel = /** @class */ (function () {
    function FileViewMoveViewModel(store, navigate) {
        this.confirmCommand = null;
        this.store = store;
        this.navigateFn = navigate;
        this.fileQuery = null;
        this.docTypesQuery = null;
        this._dest_doctype_id = null;
        this._dest_doctype_group_id = null;
        (0, mobx_1.makeAutoObservable)(this, {}, { autoBind: true });
    }
    FileViewMoveViewModel.prototype.setDocFileId = function (docfile_id) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!docfile_id)
                            return [2 /*return*/];
                        this.fileQuery = this.store.docfileStore.get(docfile_id);
                        return [4 /*yield*/, this.fileQuery.execute()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    FileViewMoveViewModel.prototype.setCustomerId = function (pratica_id, famiglia) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!pratica_id || !famiglia)
                            return [2 /*return*/];
                        this.docTypesQuery = this.store.getAllOfFamily(pratica_id, famiglia);
                        return [4 /*yield*/, this.docTypesQuery.execute()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    Object.defineProperty(FileViewMoveViewModel.prototype, "isBusy", {
        get: function () {
            if (!this.fileQuery)
                return false;
            return this.fileQuery.isBusy;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(FileViewMoveViewModel.prototype, "docFile", {
        get: function () {
            if (!this.fileQuery)
                return null;
            return this.fileQuery.result || null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(FileViewMoveViewModel.prototype, "doctypesResult", {
        get: function () {
            if (!this.docTypesQuery)
                return [];
            if (!this.docTypesQuery.result)
                return [];
            if (!this.docFile)
                return [];
            return this.docTypesQuery.result;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(FileViewMoveViewModel.prototype, "doctypes", {
        get: function () {
            var _this = this;
            return this.doctypesResult.map(function (el) {
                var _a;
                return ({
                    _id: el._id,
                    name: el.Nome,
                    disabled: el._id === ((_a = _this.docFile) === null || _a === void 0 ? void 0 : _a.CodiceDoc) && !el.multiple,
                });
            });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(FileViewMoveViewModel.prototype, "groups", {
        get: function () {
            var _this = this;
            if (!this.doctype)
                return [];
            return this.doctype.groups.map(function (el) {
                var _a, _b;
                return ({
                    _id: el._id,
                    name: el.name,
                    disabled: el._id === ((_a = _this.docFile) === null || _a === void 0 ? void 0 : _a.CodiceDocGruppo) || (!((_b = _this.doctype) === null || _b === void 0 ? void 0 : _b.multiple)),
                });
            });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(FileViewMoveViewModel.prototype, "doctype_id", {
        get: function () {
            var _a;
            return (_a = this._dest_doctype_id) !== null && _a !== void 0 ? _a : "";
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(FileViewMoveViewModel.prototype, "doctype", {
        get: function () {
            var _this = this;
            return this.doctypesResult.find(function (el) { return el._id === _this._dest_doctype_id; }) || null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(FileViewMoveViewModel.prototype, "doctype_group_id", {
        get: function () {
            var _a;
            return (_a = this._dest_doctype_group_id) !== null && _a !== void 0 ? _a : "";
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(FileViewMoveViewModel.prototype, "doctype_group", {
        get: function () {
            var _this = this;
            if (!this.doctype)
                return null;
            return this.doctype.groups.find(function (el) { return el._id === _this._dest_doctype_group_id; }) || null;
        },
        enumerable: false,
        configurable: true
    });
    FileViewMoveViewModel.prototype.selectDocType = function (dest) {
        var _a;
        var n = parseInt(dest);
        if (isNaN(n))
            return;
        this._dest_doctype_id = n;
        var doctype = this.doctypesResult.find(function (el) { return el._id === n; });
        this._dest_doctype_group_id = (_a = doctype === null || doctype === void 0 ? void 0 : doctype.groups[0]._id) !== null && _a !== void 0 ? _a : null;
    };
    FileViewMoveViewModel.prototype.selectDocTypeGroup = function (dest) {
        var n = parseInt(dest);
        if (isNaN(n))
            return;
        this._dest_doctype_group_id = n;
    };
    Object.defineProperty(FileViewMoveViewModel.prototype, "doctype_multiple", {
        get: function () {
            if (!this.doctype)
                return false;
            return this.doctype.multiple;
        },
        enumerable: false,
        configurable: true
    });
    FileViewMoveViewModel.prototype.back = function () {
        this.navigateFn("../");
    };
    FileViewMoveViewModel.prototype.confirm = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!this.docFile)
                            return [2 /*return*/];
                        if (!this._dest_doctype_id)
                            return [2 /*return*/];
                        if (!this._dest_doctype_group_id)
                            return [2 /*return*/];
                        this.confirmCommand = this.docFile.moveto({
                            doctype_id: this._dest_doctype_id,
                            doctype_group_id: this._dest_doctype_group_id
                        });
                        return [4 /*yield*/, this.confirmCommand.execute()];
                    case 1:
                        _b.sent();
                        return [4 /*yield*/, Promise.all([
                                this.store.docfileStore.getAll(this.docFile.CodiceDoc, this.docFile.CodiceDocGruppo).reload(),
                                this.store.docfileStore.getAll(this._dest_doctype_id, this._dest_doctype_group_id).reload(),
                            ])];
                    case 2:
                        _b.sent();
                        this.navigateFn("/segreteria/customers/".concat((_a = this.docFile) === null || _a === void 0 ? void 0 : _a.CFCliente, "/"));
                        return [2 /*return*/];
                }
            });
        });
    };
    Object.defineProperty(FileViewMoveViewModel.prototype, "confirmDisabled", {
        get: function () {
            if (this._dest_doctype_id === null)
                return true;
            if (this._dest_doctype_group_id === null)
                return true;
            if (!this.confirmCommand)
                return false;
            return this.confirmCommand.isBusy || this.confirmCommand.isEnded;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(FileViewMoveViewModel.prototype, "confirmBusy", {
        get: function () {
            if (!this.confirmCommand)
                return false;
            return this.confirmCommand.isBusy;
        },
        enumerable: false,
        configurable: true
    });
    return FileViewMoveViewModel;
}());
exports.default = FileViewMoveViewModel;
