"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var RejectReasonDict = {
    DATE_ERROR: "DATE_ERROR",
    UNREADABLE: "UNREADABLE",
    INCOMPLETE: "INCOMPLETE",
    USELESS: "USELESS",
    OTHER: "OTHER",
};
exports.default = RejectReasonDict;
