"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var default_idps = [
    {
        "entity_id": "https://id.eht.eu",
        "organization_name": "EtnaHitech S.C.p.A.",
        "logo_uri": "https://cdn.statically.io/gh/italia/spid-sp-access-button/master/src/production/img/spid-idp-etnaid.svg",
        "registry_link": "https://registry.spid.gov.it/entities-idp/https%3A%2F%2Fid.eht.eu?output=json"
    },
    {
        "entity_id": "https://id.lepida.it/idp/shibboleth",
        "organization_name": "Lepida S.p.A.",
        "logo_uri": "https://cdn.statically.io/gh/italia/spid-sp-access-button/master/src/production/img/spid-idp-lepidaid.svg",
        "registry_link": "https://registry.spid.gov.it/entities-idp/https%3A%2F%2Fid.lepida.it%2Fidp%2Fshibboleth?output=json"
    },
    {
        "entity_id": "https://identity.infocert.it",
        "organization_name": "InfoCert S.p.A.",
        "logo_uri": "https://cdn.statically.io/gh/italia/spid-sp-access-button/master/src/production/img/spid-idp-infocertid.svg",
        "registry_link": "https://registry.spid.gov.it/entities-idp/https%3A%2F%2Fidentity.infocert.it?output=json"
    },
    {
        "entity_id": "https://identity.sieltecloud.it",
        "organization_name": "Sielte S.p.A.",
        "logo_uri": "https://cdn.statically.io/gh/italia/spid-sp-access-button/master/src/production/img/spid-idp-sielteid.svg",
        "registry_link": "https://registry.spid.gov.it/entities-idp/https%3A%2F%2Fidentity.sieltecloud.it?output=json"
    },
    {
        "entity_id": "https://idp.namirialtsp.com/idp",
        "organization_name": "Namirial",
        "logo_uri": "https://cdn.statically.io/gh/italia/spid-sp-access-button/master/src/production/img/spid-idp-namirialid.svg",
        "registry_link": "https://registry.spid.gov.it/entities-idp/https%3A%2F%2Fidp.namirialtsp.com%2Fidp?output=json"
    },
    {
        "entity_id": "https://login.id.tim.it/affwebservices/public/saml2sso",
        "organization_name": "TI Trust Technologies srl",
        "logo_uri": "https://cdn.statically.io/gh/italia/spid-sp-access-button/master/src/production/img/spid-idp-timid.svg",
        "registry_link": "https://registry.spid.gov.it/entities-idp/https%3A%2F%2Flogin.id.tim.it%2Faffwebservices%2Fpublic%2Fsaml2sso?output=json"
    },
    {
        "entity_id": "https://loginspid.aruba.it",
        "organization_name": "ArubaPEC S.p.A.",
        "logo_uri": "https://cdn.statically.io/gh/italia/spid-sp-access-button/master/src/production/img/spid-idp-arubaid.svg",
        "registry_link": "https://registry.spid.gov.it/entities-idp/https%3A%2F%2Floginspid.aruba.it?output=json"
    },
    {
        "entity_id": "https://posteid.poste.it",
        "organization_name": "Poste Italiane SpA",
        "logo_uri": "https://cdn.statically.io/gh/italia/spid-sp-access-button/master/src/production/img/spid-idp-posteid.svg",
        "registry_link": "https://registry.spid.gov.it/entities-idp/https%3A%2F%2Fposteid.poste.it?output=json"
    },
    {
        "entity_id": "https://spid.intesa.it",
        "organization_name": "IN.TE.S.A. S.p.A.",
        "logo_uri": "https://cdn.statically.io/gh/italia/spid-sp-access-button/master/src/production/img/spid-idp-intesaid.svg",
        "registry_link": "https://registry.spid.gov.it/entities-idp/https%3A%2F%2Fspid.intesa.it?output=json"
    },
    {
        "entity_id": "https://spid.register.it",
        "organization_name": "Register.it S.p.A.",
        "logo_uri": "https://cdn.statically.io/gh/italia/spid-sp-access-button/master/src/production/img/spid-idp-spiditalia.svg",
        "registry_link": "https://registry.spid.gov.it/entities-idp/https%3A%2F%2Fspid.register.it?output=json"
    },
    {
        "entity_id": "https://spid.teamsystem.com/idp",
        "organization_name": "TeamSystem s.p.a.",
        "logo_uri": "https://cdn.statically.io/gh/italia/spid-sp-access-button/master/src/production/img/spid-idp-teamsystemid.svg",
        "registry_link": "https://registry.spid.gov.it/entities-idp/https%3A%2F%2Fspid.teamsystem.com%2Fidp?output=json"
    }
];
var validator_idp = {
    entity_id: "https://validator.spid.gov.it",
    organization_name: "SPID Validator",
    logo_uri: "https://analytics.spid.gov.it/img/logo-spid-onboarding.svg",
    registry_link: "https://registry.spid.gov.it/entities-idp/https%3A%2F%2Fvalidator.spid.gov.it?output=json",
};
function useEntraConSpid(_a) {
    var enableValidator = _a.enableValidator;
    var _b = __read((0, react_1.useState)(false), 2), loading = _b[0], setLoading = _b[1];
    var _c = __read((0, react_1.useState)(default_idps), 2), idps = _c[0], setIdps = _c[1];
    (0, react_1.useEffect)(function () {
        setLoading(true);
        fetch("https://registry.spid.gov.it/entities-idp?&output=json&custom=info_display_base")
            .then(function (response) { return response.json(); })
            .then(function (idps) {
            setIdps(idps.sort(function () { return Math.random() - 0.5; }));
            setLoading(false);
        });
    }, []);
    var idpsWithValidator = (0, react_1.useMemo)(function () { return enableValidator ? __spreadArray([validator_idp], __read(idps), false) : idps; }, [idps, enableValidator]);
    return {
        loading: loading,
        idps: idpsWithValidator,
    };
}
exports.default = useEntraConSpid;
