"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var mobx_1 = require("mobx");
var DocNecessarioComponentVM = /** @class */ (function () {
    function DocNecessarioComponentVM(doc_necessario) {
        this.doc_necessario = doc_necessario;
        (0, mobx_1.makeAutoObservable)(this, {}, { autoBind: true });
    }
    DocNecessarioComponentVM.prototype.addGroup = function () {
        this.doc_necessario.addGroup().execute();
    };
    return DocNecessarioComponentVM;
}());
exports.default = DocNecessarioComponentVM;
